.title {
  font-family: 'Inter';
  font-size: 36px;
  font-weight: 700;
}

.screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}
